<template>
    <footer>
        <section v-if="false && !props.light" class="pb-12 bg-secondary">
            <div class="page-container">
                <AtomsTextHeader :type="2" class="after:border-white header--with-inverted-line">{{ $t('Gravírování') }}</AtomsTextHeader>
                <div class="mb-4">
                    <p>{{ $t('Gravírování umí dodat šperkům nebo jiným předmětům osobní jedinečnost') }}</p>
                </div>
                <MoleculesButtonsButton arrow-right="true">{{ $t('Více') }}</MoleculesButtonsButton>
            </div>
        </section>
        <section v-if="!props.light" class="mt-12 mx-0 lg:mx-auto pb-12 lg:pt-12 lg:pb-24 bg-[#F0F2F5] xl:bg-[url('/img/novinky-prsteny.jpg')] bg-no-repeat bg-right bg-cover">
            <div class="page-container">
                <AtomsTextHeader :type="2" class="header--with-line">{{ $t('Přihlášení k newsletteru') }}</AtomsTextHeader>

                <div class="flex items-center gap-1 xl:w-1/2 p-1 rounded-md bg-white">
                    <AtomsImagesIcon icon="mail" size="3xl" class="hidden lg:block mx-2 lg:mx-4" />
                    <MoleculesFormsValidatedField type="email" v-model="subscriptionEmail" @submit="subscribeEmail" :placeholder="$t('Váš e-mail')" :error-message="$t('Vyplňte e-mail ve správném formátu')" :button-text="$t('Odeslat')" :required="true" />
                </div>
            </div>
        </section>
        <section>
            <div class="page-container pt-12 lg:pt-32">
                <div class="flex flex-wrap gap-16">
                    <div class="w-full lg:w-1/3">
                        <img class="lg:w-2/3 mx-auto lg:mx-0" src="https://www.snubniprsteny4u.eu/img/logo.png" />
                        <div class="xl:w-4/5 pt-8 text-center lg:text-left text-gray">
                            <p>Tady jsou vaše budoucí snubní prsteny. A vybírejte pečlivě. Bude to dost možná ten nejdůležitější prsten vašeho života. Jedno <strong>ANO</strong> už bylo vysloveno.</p>
                        </div>
                    </div>
                    <div class="flex-1 lg:w-2/3">
                        <div class="mx-auto xl:flex xl:gap-4 xl:justify-center pb-6 border-b-2 lg:border-b-4 border-secondary text-gray">
                            <a class="block mb-6 xl:grow xl:text-center hover:text-primary" :href="`tel:${appConfig.contactPhone}`">
                                <AtomsImagesIcon icon="phone" :filled="true" class="mr-4 xl:mr-2" /> {{ appConfig.contactPhone }}
                            </a>
                            <a class="block mb-6 xl:grow xl:text-center hover:text-primary" :href="`mailto:${appConfig.contactEmail}`">
                                <AtomsImagesIcon icon="mail" :filled="true" class="mr-4 xl:mr-2" /> {{ appConfig.contactEmail }}
                            </a>
                            <a class="block mb-6 xl:grow xl:text-center hover:text-primary" :href="appConfig.fbUrl" target="_blank">
                                <AtomsImagesIcon icon="FB" :filled="true" class="mr-4 xl:mr-2" /> Facebook / snubniprsteny4u
                            </a>
                        </div>
                        <ul class="footer-links flex flex-wrap gap-4 justify-between py-8 xl:p-14 md:flex-nowrap pb-12 border-b-2 border-silver">
                            <li class="w-full lg:w-auto">
                                <strong>Menu</strong>
                                <ul>
                                    <li><AtomsGlobalLink to="/konfigurator">Konfigurátor snubních prstenů</AtomsGlobalLink></li>
                                    <li><AtomsGlobalLink to="/snubni-prsteny">Snubní prsteny</AtomsGlobalLink></li>
                                    <li><AtomsGlobalLink to="/zasnubni-prsteny">Zásnubní prsteny</AtomsGlobalLink></li>
                                    <li><AtomsGlobalLink to="/krabicky">Krabičky</AtomsGlobalLink></li>
                                    <li><AtomsGlobalLink to="/prislusenstvi">Příslušenství a doplňky</AtomsGlobalLink></li>
                                </ul>
                            </li>
                            <li class="w-full lg:w-auto">
                                <strong>Informace</strong>
                                <ul>
                                    <li><AtomsGlobalLink to="/magazin">Magazín</AtomsGlobalLink></li>
                                    <li><AtomsGlobalLink to="/doprava-a-platba">Doprava a platba</AtomsGlobalLink></li>
                                    <li><AtomsGlobalLink to="/vraceni-a-vymena">Vrácení zboží</AtomsGlobalLink></li>
                                    <li><AtomsGlobalLink to="/obchodni-podminky">Obchodní podmínky</AtomsGlobalLink></li>
                                    <li><AtomsGlobalLink to="/reklamacni-rad">Reklamační řád</AtomsGlobalLink></li>
                                </ul>
                            </li>
                            <li class="w-full lg:w-auto">
                                <strong>O nás</strong>
                                <ul>
                                    <li><AtomsGlobalLink to="/kontakty">Kontakty</AtomsGlobalLink></li>
                                    <li><AtomsGlobalLink to="/prodejna-hk">Naše prodejna</AtomsGlobalLink></li>
                                    <li><AtomsGlobalLink to="/registrace-u-puncovniho-uradu">Puncovní úřad</AtomsGlobalLink></li>
                                    <li><AtomsGlobalLink to="/ochrana-osobnich-udaju">GDPR</AtomsGlobalLink></li>
                                </ul>
                            </li>
                        </ul>

                    </div>
                </div>
                <div class="flex flex-wrap gap-4 sm:justify-between py-8">
                    <div class="grow w-full sm:grow-0 sm:w-auto text-center">&copy; {{ new Date().getFullYear() }} Šperky4U s.r.o. | <a href="https://drivenet.cz">DriveNet s.r.o.</a></div>
                    <div class="grow w-full sm:grow-0 sm:w-auto text-center"><img class="mx-auto" src="/img/platba-kartou.png" /></div>
                </div>
            </div>
        </section>
    </footer>
</template>
<script setup>

const props = defineProps({
    light: Boolean
});

const appConfig = useAppConfig();
const locale = useLocale().getLocale();
const i18n = useI18n();

const subscriptionEmail = ref('');

const subscribeEmail = async () => {
    await useApiFetch(`/api/mutations/newsletterSubscribe?email=${encodeURIComponent(subscriptionEmail.value)}&source=${appConfig.newsletterSubscribeSources.footer}&currencyId=${locale.currencyId}&languageId=${locale.languageId}`);
    subscriptionEmail.value = '';

    useMessage().show(i18n.t('E-mail byl zaznamenán, děkujeme'), 'ok', false, false, i18n.t('Dobře'), 3);
}

</script>
<style lang="postcss">
.footer-links>li>strong {
    @apply block pb-4 text-lg uppercase;
}

.footer-links>li>ul {
    @apply space-y-1;
}

.footer-links a {
    @apply text-gray hover:text-primary hover:underline;
}
</style>