<template>
    <div id="topBar" v-if="show" class="relative z-40 text-center transition-all" :style="`color: ${props.textColor}; background-color: ${props.backgroundColor};`">
        <div class="page-container flex gap-2 items-center p-3 relative">
            <div class="flex-1" v-html="htmlContentWithAnnotatedLinksForAnalytics"></div>
            <button @click="close">
                <AtomsImagesIcon icon="zavreni" :title="$t('Zavřít')" />
            </button>
        </div>
    </div>
</template>
<script setup>

const props = defineProps({
    isEnabled: Boolean,
    textColor: String,
    backgroundColor: String,
    html: String,
    campaign: String
});

const cookieName = 'topBarClosed';

let show = props.isEnabled &= useShopCookie().read(cookieName) == null;

const htmlContentWithAnnotatedLinksForAnalytics = props.html.replaceAll('<a', `<a data-promotioncampaign="${props.campaign?.replace('"', '')}" data-promotiontype="text" data-promotionname="${props.html.substring(0, 100).replace('"', '')}"`);

const close = () => {
    useShopCookie().set(cookieName, true, 24);
    document.getElementById('topBar').style.maxHeight = 0;
}

const setMaxHeight = () => {
    const element = document.getElementById('topBar');
    if (element) {
        element.style.maxHeight = null;
        const height = element.clientHeight;
        element.style.maxHeight = height + 'px';
    }
}

onMounted(() => {
    window.addEventListener('resize', () => { setMaxHeight(); });
    setMaxHeight();
});

</script>